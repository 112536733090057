<template>
  <div>
    <Alert
      v-if="!relationInfo"
      :variant="'secondary'"
      :dismissible="false"
      class="flex items-center"
    >
      <Lucide icon="AlertCircle" class="w-6 h-6 mr-2" />

      {{ $t("field_relationship_not_setup") }}
    </Alert>

    <template v-else>
      <MultiSelect
        v-if="!!relationInfo && !!relatedCollection"
        optionValue="value"
        optionLabel="label"
        dataKey="value"
        :options="displayOptions"
        :virtualScrollerOptions="virtualScrollerOptions"
        :editable="false"
        :tabindex="props.field.meta.sortPosition"
        :aria-label="getFieldLabel(props.field)"
        :class="['w-full']"
        inputClass="form-select"
        :modelValue="selectedItemIds"
        display="chip"
        :loading="isLoading"
        :placeholder="isLoading ? $t('items_is_loading') : ''"
        :disabled="isLoading || props.field.meta.isReadonly"
        @update:modelValue="onUpdateModelValue"
        v-on="dropdownEventListeners"
      >
        <template #header>
          <div class="p-dropdown-header">
            <div class="p-dropdown-filter-container">
              <input
                type="text"
                autocomplete="off"
                role="search"
                :value="search"
                :class="['p-dropdown-filter', 'p-inputtext', 'p-component']"
                v-on="searchEventListeners"
              />
              <span :class="['p-dropdown-filter-icon', 'pi pi-search']"></span>
            </div>
          </div>
        </template>

        <template #chip="slotProps">
          <div
            v-if="!!displayItemsMap[slotProps.value] && !!slotProps.value"
            class="flex items-center justify-between p-1"
          >
            <RenderTemplate
              :collectionName="relatedCollection.id"
              :item="displayItemsMap[slotProps.value]"
              :fieldInfo="props.field"
              :defaultTemplate="defaultDisplayTemplate"
            />
            <Button
              v-if="canNavigateToRelationalItem(relatedCollection)"
              size="small"
              variant="transparent"
              rounded
              @click="
                routeToItem(
                  relatedCollection.id,
                  displayItemsMap[slotProps.value].id,
                  router,
                )
              "
            >
              <i class="fa-solid fa-arrow-up-right-from-square"></i>
            </Button>
          </div>

          <template v-else>
            {{ slotProps.value }}
          </template>
        </template>

        <template #option="slotProps">
          <RenderTemplate
            v-if="!!displayItemsMap[slotProps.option.value]"
            :collectionName="relatedCollection.id"
            :item="displayItemsMap[slotProps.option.value]"
            :fieldInfo="props.field"
            :defaultTemplate="defaultDisplayTemplate"
          />

          <template v-else>
            {{ slotProps.option.label }}
          </template>
        </template>
      </MultiSelect>
    </template>
  </div>
</template>

<script setup lang="ts">
  import { toRef, computed, ref, shallowRef } from "vue";
  import { useRouter } from "vue-router";
  import { VirtualScrollerProps } from "primevue/virtualscroller";
  import { useRelationO2M } from "~/api/relations/composables/useRelationO2M";
  import {
    defineEmitUpdateItemFieldDataPayload,
    FieldInterfaceEmitId,
    FieldInterfaceEmits,
  } from "~/api/field-interfaces/emits";
  import { FieldFormInterfaceProps } from "~/api/field-interfaces/types";
  import {
    defineFieldManyData,
    FieldManyRelationalData,
    addRelatedPrimaryKeyToFields,
    getFieldLabel,
  } from "~/entities/field";
  import { canNavigateToRelationalItem } from "~/api/collections/utils/availability";
  import { RenderTemplate } from "~/entities/render-template";
  import { MultiSelect } from "~/shared/ui/MultiSelect";
  import { useRelationMultiple } from "~/api/relations/composables/useRelationMultiple";
  import {
    adjustFieldsForDisplays,
    getFieldsFromTemplate,
  } from "~/api/field-displays/utils";
  import { useCollecitonsStore } from "~/stores/collections";
  import CollectionInterface from "~/api/collections/entities/CollectionInterface";
  import { OptionItem } from "~/components/DropdownSingleRelationRenderer/types";
  import Button from "~/shared/ui/Button";
  import { routeToItem, type IItem, type ItemID } from "~/entities/item";
  import Alert from "~/shared/ui/Alert";
  import Lucide from "~/shared/ui/Lucide";

  const props = defineProps<FieldFormInterfaceProps>();
  const emit = defineEmits<FieldInterfaceEmits>();

  const router = useRouter();

  const collectionName = toRef(props.collection.id);
  const fieldName = toRef(props.field.name);

  const { relationInfo, relatedCollection } = useRelationO2M(collectionName, fieldName);

  const selectedItemIds = computed(() => {
    const currentIds =
      props.item.getDataProperty(fieldName.value)?.currentJunctionItemIds ?? [];

    return currentIds;
  });

  const onUpdateModelValue = (event: string[]) => {
    emit(
      FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA,
      defineEmitUpdateItemFieldDataPayload({
        collectionName: props.collection.id,
        fieldName: props.field.name,
        updatedData: handleUpdateManyData(event),
      }),
    );
  };
  const collectionsStore = useCollecitonsStore();

  const defaultDisplayTemplate = computed<string>(() => {
    const fieldTemplate = props.field.meta.displayOptions?.template;
    if (!!fieldTemplate) return fieldTemplate;

    const collectionTemplate = relatedCollection.value?.meta?.displayTemplate;
    const primaryField = relatedCollection.value?.getPrimaryFieldInfo();

    return collectionTemplate || `{{ ${primaryField!.name} }}`;
  });

  const fields = computed(() => {
    if (!relationInfo.value) return [];

    const targetCollection: CollectionInterface = collectionsStore.getCollection(
      relationInfo.value.relatedCollection!,
    )!;
    if (!targetCollection) return [];

    const displayFields = adjustFieldsForDisplays(
      getFieldsFromTemplate(defaultDisplayTemplate.value),
      targetCollection,
    );

    return addRelatedPrimaryKeyToFields(targetCollection, displayFields);
  });

  const query = computed(() => {
    return {
      limit: -1,
      fields: fields.value || ["id"],
    };
  });

  const { fetchedItems, isLoading, updateRelationItems } = useRelationMultiple(
    computed(() => props.item.id),
    relationInfo,
    query,
  );

  const displayOptions = computed(() => fetchedItems.value.map(castItemToOption));

  const displayItemsMap = computed(() =>
    fetchedItems.value.reduce<Record<ItemID, IItem>>((hashMap, item) => {
      if (item.id in hashMap) return hashMap;

      hashMap[item.id] = item;
      return hashMap;
    }, {}),
  );

  const dropdownEventListeners = {
    "before-show": async () => {
      await updateRelationItems();
    },
  };

  const search = ref<string>("");

  const searchEventListeners = {
    input: (event: InputEvent) => {
      const target = event.target as HTMLInputElement;

      search.value = target.value;
    },
  };

  const virtualScrollerOptions = shallowRef<VirtualScrollerProps>({
    lazy: true,
    showLoader: false,
    appendOnly: true,
    numToleratedItems: 0,
    loaderDisabled: true,
    orientation: "vertical",
    itemSize: 42,
  });

  function castItemToOption(item: IItem): OptionItem {
    return {
      label: String(item.id) || "",
      value: item.id,
    };
  }

  function handleUpdateManyData(data: string[]): FieldManyRelationalData {
    return defineFieldManyData({
      currentJunctionItemIds: data,
      newRelatedItemIds: [],
      removeJunctionItemIds: [],
      create: [],
    });
  }
</script>

<style scoped></style>
